export default {
  category: 'book',
  name: 'Storyworthy',
  author: 'Matthew Dicks',
  date: '11/21/24',
  path: '/notes/storyworthy',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-storyworthy-matthew-dicks.jpg',
  tags: ['Business', 'Communication', 'Memoir'],
  time: 47,
  rating: 8.1,
  review: '',
  link: 'https://www.amazon.com/Storyworthy-Engage-Persuade-through-Storytelling/dp/1608685489',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/671c16168c02fb105ae66fc1',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
