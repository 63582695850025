import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import { apps, work } from './data';
import AboutPage from './pages/AboutPage';
import AppPage from './pages/AppPage';
import AppsPage from './pages/AppsPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import JobPage from './pages/JobPage';
import NotesPage from './pages/NotesPage';
import NotFoundPage from './pages/NotFoundPage';
import NowPage from './pages/NowPage';
import PodcastPage from './pages/PodcastPage';
import UpdatesPage from './pages/UpdatesPage';
import VideosPage from './pages/VideosPage';
import WorkPage from './pages/WorkPage';
import WritingsPage from './pages/WritingsPage';

const AntiLaissezFaire = loadable(() => import('./write/components/AntiLaissezFaire'));
const ApproachWithLove = loadable(() => import('./write/components/ApproachWithLove'));
const AStepInTheRightDirection = loadable(() =>
  import('./write/components/AStepInTheRightDirection')
);
const Be = loadable(() => import('./write/components/Be'));
const BeginnersMind = loadable(() => import('./write/components/BeginnersMind'));
const BeingHigh = loadable(() => import('./write/components/BeingHigh'));
const BirthdayFeels = loadable(() => import('./write/components/BirthdayFeels'));
const Blip = loadable(() => import('./write/components/Blip'));
const BooksIReadIn2019 = loadable(() => import('./write/components/BooksIReadIn2019'));
const BornAgain = loadable(() => import('./write/components/BornAgain'));
const Consistency = loadable(() => import('./write/components/Consistency'));
const DontAskForItToBeEasy = loadable(() => import('./write/components/DontAskForItToBeEasy'));
const DoSomething = loadable(() => import('./write/components/DoSomething'));
const DoTheOpposite = loadable(() => import('./write/components/DoTheOpposite'));
const Doubt = loadable(() => import('./write/components/Doubt'));
const Failure = loadable(() => import('./write/components/Failure'));
const FamilyMatters = loadable(() => import('./write/components/FamilyMatters'));
const Favorite = loadable(() => import('./write/components/Favorite'));
const Fear = loadable(() => import('./write/components/Fear'));
const FindingHappiness = loadable(() => import('./write/components/FindingHappiness'));
const GoalsFor2020 = loadable(() => import('./write/components/GoalsFor2020'));
const GoalsFor26 = loadable(() => import('./write/components/GoalsFor26'));
const GoalsVsResolutions = loadable(() => import('./write/components/GoalsVsResolutions'));
const HaveSomethingToDo = loadable(() => import('./write/components/HaveSomethingToDo'));
const HaveYouEverTriedToBeARapper = loadable(() =>
  import('./write/components/HaveYouEverTriedToBeARapper')
);
const Heaven = loadable(() => import('./write/components/Heaven'));
const HeresTheThingTho = loadable(() => import('./write/components/HeresTheThingTho'));
const HigherStandard = loadable(() => import('./write/components/HigherStandard'));
const HowIRememberEveryThingWithTwos = loadable(() =>
  import('./write/components/HowIRememberEveryThingWithTwos')
);
const HowPeopleWakeUpAt430AMToWork = loadable(() =>
  import('./write/components/HowPeopleWakeUpAt430AMToWork')
);
const HowToBecomeAProgrammer = loadable(() => import('./write/components/HowToBecomeAProgrammer'));
const HowToNotTakeThingsPersonally = loadable(() =>
  import('./write/components/HowToNotTakeThingsPersonally')
);
const HowToRaiseTheCommunitysLevelOfIntelligence = loadable(() => import('./write/components/HowToRaiseTheCommunitysLevelOfIntelligence'));
const IAmCommittedTo = loadable(() => import('./write/components/IAmCommittedTo'));
const InterestingPeople = loadable(() => import('./write/components/InterestingPeople'));
const IThinkICan = loadable(() => import('./write/components/IThinkICan'));
const LayingOnACouch = loadable(() => import('./write/components/LayingOnACouch'));
const Levels = loadable(() => import('./write/components/Levels'));
const Liberty = loadable(() => import('./write/components/Liberty'));
const ListenToYourself = loadable(() => import('./write/components/ListenToYourself'));
const LoveVsFear = loadable(() => import('./write/components/LoveVsFear'));
const MVS = loadable(() => import('./write/components/MVS'));
const NewYearsResolutionsFor2020 = loadable(() =>
  import('./write/components/NewYearsResolutionsFor2020')
);
const NobodyGivesAFuck = loadable(() => import('./write/components/NobodyGivesAFuck'));
const OneDayAtATime = loadable(() => import('./write/components/OneDayAtATime'));
const OnTheShouldersOfGiants = loadable(() => import('./write/components/OnTheShouldersOfGiants'));
const PainAndPleasure = loadable(() => import('./write/components/PainAndPleasure'));
const Pause = loadable(() => import('./write/components/Pause'));
const PeopleChange = loadable(() => import('./write/components/PeopleChange'));
const Posibility = loadable(() => import('./write/components/Posibility'));
const PositiveThoughtExercises = loadable(() =>
  import('./write/components/PositiveThoughtExercises')
);
const Potential = loadable(() => import('./write/components/Potential'));
const ReadThisIfYouAreStuck = loadable(() => import('./write/components/ReadThisIfYouAreStuck'));
const RealizationsFromRealNegotiations = loadable(() =>
  import('./write/components/RealizationsFromRealNegotiations')
);
const ReasonsWhyYouShouldLearnHowToProgram = loadable(() =>
  import('./write/components/ReasonsWhyYouShouldLearnHowToProgram')
);
const ReasonsToUseAware = loadable(() => import('./write/components/ReasonsToUseAware'));
const ReasonsToUseMuse = loadable(() => import('./write/components/ReasonsToUseMuse'));
const Resolution = loadable(() => import('./write/components/Resolution'));
const Sacrifice = loadable(() => import('./write/components/Sacrifice'));
const SeeWhatYouWantToSee = loadable(() => import('./write/components/SeeWhatYouWantToSee'));
const SelfLove = loadable(() => import('./write/components/SelfLove'));
const Solitude = loadable(() => import('./write/components/Solitude'));
const TheBestCaliforniaBurritoInSanDiego = loadable(() =>
  import('./write/components/TheBestCaliforniaBurritoInSanDiego')
);
const TheLongGame = loadable(() => import('./write/components/TheLongGame'));
const TheOptimalState = loadable(() => import('./write/components/TheOptimalState'));
const ThePoint = loadable(() => import('./write/components/ThePoint'));
const Time = loadable(() => import('./write/components/Time'));
const TimeHealsAll = loadable(() => import('./write/components/TimeHealsAll'));
const Top10BenefitsOfWritingThingsDown = loadable(() => import('./write/components/Top10BenefitsOfWritingThingsDown'));
const WaterYourGarden = loadable(() => import('./write/components/WaterYourGarden'));
const WaysToStopForgettingThingsWithTwos = loadable(() => import('./write/components/WaysToStopForgettingThingsWithTwos'));
const WeEndedUpNextToEachOther = loadable(() =>
  import('./write/components/WeEndedUpNextToEachOther')
);
const WhatDoWeNeed = loadable(() => import('./write/components/WhatDoWeNeed'));
const WhatDoYouSee = loadable(() => import('./write/components/WhatDoYouSee'));
const WhatHappensWhenWeSlowDown = loadable(() =>
  import('./write/components/WhatHappensWhenWeSlowDown')
);
const WhatIsMeaningful = loadable(() => import('./write/components/WhatIsMeaningful'));
const WhatMakesYouFeelGreat = loadable(() => import('./write/components/WhatMakesYouFeelGreat'));
const WhatWillBe = loadable(() => import('./write/components/WhatWillBe'));
const WhatYouLove = loadable(() => import('./write/components/WhatYouLove'));
const WhatYouWant = loadable(() => import('./write/components/WhatYouWant'));
const WhenYoureFeelingDown = loadable(() => import('./write/components/WhenYoureFeelingDown'));
const WhyDoWeHurtOurselves = loadable(() => import('./write/components/WhyDoWeHurtOurselves'));
const WhyEverythingIsTheGreatest = loadable(() =>
  import('./write/components/WhyEverythingIsTheGreatest')
);
const WhyIBuiltAware = loadable(() => import('./write/components/WhyIBuiltAware'));
const WhyILeftMyDreamJobAtGoogleForTwos = loadable(() => import('./write/components/WhyILeftMyDreamJobAtGoogleForTwos'));
const WhyMe = loadable(() => import('./write/components/WhyMe'));
const YouAreYou = loadable(() => import('./write/components/YouAreYou'));
const YouAreYouBecauseOfYou = loadable(() => import('./write/components/YouAreYouBecauseOfYou'));
const YouCanDoIt = loadable(() => import('./write/components/YouCanDoIt'));
const YouCanLearnAnything = loadable(() => import('./write/components/YouCanLearnAnything'));

const AboveTheLine = loadable(() => import('./notes/components/AboveTheLine'));
const ABriefHistoryOfTime = loadable(() => import('./notes/components/ABriefHistoryOfTime'));
const ACuriousMind = loadable(() => import('./notes/components/ACuriousMind'));
const AGuideToTheGoodLife = loadable(() => import('./notes/components/AGuideToTheGoodLife'));
const AlgorithmsToLiveBy = loadable(() => import('./notes/components/AlgorithmsToLiveBy'));
const Alibaba = loadable(() => import('./notes/components/Alibaba'));
const AMillionMilesInAThousandYears = loadable(() =>
  import('./notes/components/AMillionMilesInAThousandYears')
);
const AmpItUp = loadable(() => import('./notes/components/AmpItUp'));
const AmusingOurselvesToDeath = loadable(() =>
  import('./notes/components/AmusingOurselvesToDeath')
);
const ANewEarth = loadable(() => import('./notes/components/ANewEarth'));
const Antifragile = loadable(() => import('./notes/components/Antifragile'));
const AlreadyFree = loadable(() => import('./notes/components/AlreadyFree'));
const AnythingYouWant = loadable(() => import('./notes/components/AnythingYouWant'));
const AsAManThinketh = loadable(() => import('./notes/components/AsAManThinketh'));
const AsAManThinketh2 = loadable(() => import('./notes/components/AsAManThinketh2'));
const AskAndItIsGiven = loadable(() => import('./notes/components/AskAndItIsGiven'));
const AShortHistoryOfNearlyEverything = loadable(() =>
  import('./notes/components/AShortHistoryOfNearlyEverything')
);
const AtlasShrugged = loadable(() => import('./notes/components/AtlasShrugged'));
const AtomicHabits = loadable(() => import('./notes/components/AtomicHabits'));
const Attached = loadable(() => import('./notes/components/Attached'));
const AwakenTheGiantWithin = loadable(() => import('./notes/components/AwakenTheGiantWithin'));
const Awareness = loadable(() => import('./notes/components/Awareness'));
const Backable = loadable(() => import('./notes/components/Backable'));
const BarbarianDays = loadable(() => import('./notes/components/BarbarianDays'));
const BecomingWise = loadable(() => import('./notes/components/BecomingWise'));
const BenjaminFranklin = loadable(() => import('./notes/components/BenjaminFranklin'));
const BhagavadGita = loadable(() => import('./notes/components/BhagavadGita'));
const BigBets = loadable(() => import('./notes/components/BigBets'));
const BigMagic = loadable(() => import('./notes/components/BigMagic'));
const BirdByBird = loadable(() => import('./notes/components/BirdByBird'));
const BlueOceanStrategy = loadable(() => import('./notes/components/BlueOceanStrategy'));
const BraveNewWorld = loadable(() => import('./notes/components/BraveNewWorld'));
const BreakthroughAdvertising = loadable(() => import('./notes/components/BreakthroughAdvertising'));
const BuddhismWithoutBeliefs = loadable(() => import('./notes/components/BuddhismWithoutBeliefs'));
const BuildingASecondBrain = loadable(() => import('./notes/components/BuildingASecondBrain'));
const BuildingAStoryBrand = loadable(() => import('./notes/components/BuildingAStoryBrand'));
const BuiltToLast = loadable(() => import('./notes/components/BuiltToLast'));
const Calypso = loadable(() => import('./notes/components/Calypso'));
const CantHurtMe = loadable(() => import('./notes/components/CantHurtMe'));
const ChooseYourself = loadable(() => import('./notes/components/ChooseYourself'));
const CleanCode = loadable(() => import('./notes/components/CleanCode'));
const CodeComplete = loadable(() => import('./notes/components/CodeComplete'));
const CommonSense = loadable(() => import('./notes/components/CommonSense'));
const ConceptualBlockbusting = loadable(() => import('./notes/components/ConceptualBlockbusting'));
const Conscious = loadable(() => import('./notes/components/Conscious'));
const Contagious = loadable(() => import('./notes/components/Contagious'));
const CourageIsCalling = loadable(() => import('./notes/components/CourageIsCalling'));
const CrackingTheCodingInterview = loadable(() =>
  import('./notes/components/CrackingTheCodingInterview')
);
const CreativityInc = loadable(() => import('./notes/components/CreativityInc'));
const CrossingTheChasm = loadable(() => import('./notes/components/CrossingTheChasm'));
const CrushIt = loadable(() => import('./notes/components/CrushIt'));
const CssSecrets = loadable(() => import('./notes/components/CssSecrets'));
const DareToLead = loadable(() => import('./notes/components/DareToLead'));
const Decoded = loadable(() => import('./notes/components/Decoded'));
const DeepWork = loadable(() => import('./notes/components/DeepWork'));
const DeliveringHappiness = loadable(() => import('./notes/components/DeliveringHappiness'));
const DesignAsArt = loadable(() => import('./notes/components/DesignAsArt'));
const DesignPatternsByTutorials = loadable(() => import('./notes/components/DesignPatternsByTutorials'));
const DontLoseYourMindLoseYourWeight = loadable(() => import('./notes/components/DontLoseYourMindLoseYourWeight'));
const DontMakeMeThink = loadable(() => import('./notes/components/DontMakeMeThink'));
const DontWorryMakeMoney = loadable(() => import('./notes/components/DontWorryMakeMoney'));
const DopamineNation = loadable(() => import('./notes/components/DopamineNation'));
const DroppingAshesOnTheBuddha = loadable(() =>
  import('./notes/components/DroppingAshesOnTheBuddha')
);
const EffectiveObjectiveC20 = loadable(() => import('./notes/components/EffectiveObjectiveC20'));
const EgoIsTheEnemy = loadable(() => import('./notes/components/EgoIsTheEnemy'));
const ElonMusk = loadable(() => import('./notes/components/ElonMusk'));
const EloquentJavaScript = loadable(() => import('./notes/components/EloquentJavaScript'));
const EmotionalIntelligence = loadable(() => import('./notes/components/EmotionalIntelligence'));
const EmotionalIntelligence20 = loadable(() =>
  import('./notes/components/EmotionalIntelligence20')
);
const Enchiridion = loadable(() => import('./notes/components/Enchiridion'));
const Essentialism = loadable(() => import('./notes/components/Essentialism'));
const EverybodyWrites = loadable(() => import('./notes/components/EverybodyWrites'));
const ExitRight = loadable(() => import('./notes/components/ExitRight'));
const ExpertSecrets = loadable(() => import('./notes/components/ExpertSecrets'));
const ExtraordinaryGolf = loadable(() => import('./notes/components/ExtraordinaryGolf'));
const FatLossForever = loadable(() => import('./notes/components/FatLossForever'));
const FitForPurpose = loadable(() => import('./notes/components/FitForPurpose'));
const FlashBoys = loadable(() => import('./notes/components/FlashBoys'));
const Flow = loadable(() => import('./notes/components/Flow'));
const FourThousandWeeks = loadable(() => import('./notes/components/FourThousandWeeks'));
const GettingReal = loadable(() => import('./notes/components/GettingReal'));
const GettingThingsDone = loadable(() => import('./notes/components/GettingThingsDone'));
const GettingToYes = loadable(() => import('./notes/components/GettingToYes'));
const GoodProfit = loadable(() => import('./notes/components/GoodProfit'));
const GoodToGreat = loadable(() => import('./notes/components/GoodToGreat'));
const Greenlights = loadable(() => import('./notes/components/Greenlights'));
const GridSystemsInGraphicDesign = loadable(() =>
  import('./notes/components/GridSystemsInGraphicDesign')
);
const Grit = loadable(() => import('./notes/components/Grit'));
const HatchingTwitter = loadable(() => import('./notes/components/HatchingTwitter'));
const HealingBackPain = loadable(() => import('./notes/components/HealingBackPain'));
const HighOutputManagement = loadable(() => import('./notes/components/HighOutputManagement'));
const HillbillyElegy = loadable(() => import('./notes/components/HillbillyElegy'));
const HomeIsWhereWeStartFrom = loadable(() => import('./notes/components/HomeIsWhereWeStartFrom'));
const Hooked = loadable(() => import('./notes/components/Hooked'));
const HowGoogleWorks = loadable(() => import('./notes/components/HowGoogleWorks'));
const HowNotToDie = loadable(() => import('./notes/components/HowNotToDie'));
const HowToGetRich = loadable(() => import('./notes/components/HowToGetRich'));
const HowToHustleAndWin = loadable(() => import('./notes/components/HowToHustleAndWin'));
const HowToReadABook = loadable(() => import('./notes/components/HowToReadABook'));
const HowToSellAnythingToAnyone = loadable(() =>
  import('./notes/components/HowToSellAnythingToAnyone')
);
const HowToStopWorryingAndStartLiving = loadable(() =>
  import('./notes/components/HowToStopWorryingAndStartLiving')
);
const HowToTakeSmartNotes = loadable(() =>
  import('./notes/components/HowToTakeSmartNotes')
);
const HowToWinFriendsAndInfluencePeople = loadable(() =>
  import('./notes/components/HowToWinFriendsAndInfluencePeople')
);
const HowWillYouMeasureYourLife = loadable(() =>
  import('./notes/components/HowWillYouMeasureYourLife')
);
const HtmlCss = loadable(() => import('./notes/components/HtmlCss'));
const Ikigai = loadable(() => import('./notes/components/Ikigai'));
const InfiniteJest = loadable(() => import('./notes/components/InfiniteJest'));
const Influence = loadable(() => import('./notes/components/Influence'));
const InPieces = loadable(() => import('./notes/components/InPieces'));
const InPraiseOfShadows = loadable(() => import('./notes/components/InPraiseOfShadows'));
const InSearchOfExcellence = loadable(() => import('./notes/components/InSearchOfExcellence'));
const Insight = loadable(() => import('./notes/components/Insight'));
const Inspired = loadable(() => import('./notes/components/Inspired'));
const IntroductionToAlgorithms = loadable(() =>
  import('./notes/components/IntroductionToAlgorithms')
);
const IOSProgramming = loadable(() => import('./notes/components/IOSProgramming'));
const JavaScriptTheDefinitiveGuide = loadable(() =>
  import('./notes/components/JavaScriptTheDefinitiveGuide')
);
const JonathanLivingstonSeagull = loadable(() =>
  import('./notes/components/JonathanLivingstonSeagull')
);
const LearningCocoaWithObjectiveC = loadable(() =>
  import('./notes/components/LearningCocoaWithObjectiveC')
);
const LearningNodejs = loadable(() => import('./notes/components/LearningNodejs'));
const LettersFromAStoic = loadable(() => import('./notes/components/LettersFromAStoic'));
const LoneSurvivor = loadable(() => import('./notes/components/LoneSurvivor'));
const LordOfTheFlies = loadable(() => import('./notes/components/LordOfTheFlies'));
const LoveYourselfLikeYourLifeDependsOnIt = loadable(() => import('./notes/components/LoveYourselfLikeYourLifeDependsOnIt'));
const MadeInAmerica = loadable(() => import('./notes/components/MadeInAmerica'));
const MadeToStick = loadable(() => import('./notes/components/MadeToStick'));
const MakeTime = loadable(() => import('./notes/components/MakeTime'));
const MakeYourBed = loadable(() => import('./notes/components/MakeYourBed'));
const MakeYourMark = loadable(() => import('./notes/components/MakeYourMark'));
const MansSearchForMeaning = loadable(() => import('./notes/components/MansSearchForMeaning'));
const MastersOfDoom = loadable(() => import('./notes/components/MastersOfDoom'));
const Meditations = loadable(() => import('./notes/components/Meditations'));
const MemoirsOfAGeisha = loadable(() => import('./notes/components/MemoirsOfAGeisha'));
const MentalToughnessSecretsOfTheWorldClass = loadable(() => import('./notes/components/MentalToughnessSecretsOfTheWorldClass'));
const Mindset = loadable(() => import('./notes/components/Mindset'));
const MythicalManMonth = loadable(() => import('./notes/components/MythicalManMonth'));
const NeverEatAlone = loadable(() => import('./notes/components/NeverEatAlone'));
const NeverSplitTheDifference = loadable(() => import('./notes/components/NeverSplitTheDifference'));
const NoMoreMrNiceGuy = loadable(() => import('./notes/components/NoMoreMrNiceGuy'));
const ObjectiveCProgramming = loadable(() => import('./notes/components/ObjectiveCProgramming'));
const ObjectOrientedJavaScript = loadable(() =>
  import('./notes/components/ObjectOrientedJavaScript')
);
const OffTheBeatenTrail = loadable(() => import('./notes/components/OffTheBeatenTrail'));
const OnTheShortnessOfLife = loadable(() => import('./notes/components/OnTheShortnessOfLife'));
const Originals = loadable(() => import('./notes/components/Originals'));
const Outliers = loadable(() => import('./notes/components/Outliers'));
const OutwittingTheDevil = loadable(() => import('./notes/components/OutwittingTheDevil'));
const PeaceIsEveryStep = loadable(() => import('./notes/components/PeaceIsEveryStep'));
const PerennialSeller = loadable(() => import('./notes/components/PerennialSeller'));
const PerfectlyYourself = loadable(() => import('./notes/components/PerfectlyYourself'));
const PlantBasedHighProteinCookbook = loadable(() => import('./notes/components/PlantBasedHighProteinCookbook'));
const PlayBigger = loadable(() => import('./notes/components/PlayBigger'));
const PoorCharliesAlmanack = loadable(() => import('./notes/components/PoorCharliesAlmanack'));
const PourYourHeartIntoIt = loadable(() => import('./notes/components/PourYourHeartIntoIt'));
const Principles = loadable(() => import('./notes/components/Principles'));
const ProductLedGrowth = loadable(() => import('./notes/components/ProductLedGrowth'));
const ProgrammingInterviewsExposed = loadable(() =>
  import('./notes/components/ProgrammingInterviewsExposed')
);
const ProgrammingPearls = loadable(() => import('./notes/components/ProgrammingPearls'));
const PurpleCow = loadable(() => import('./notes/components/PurpleCow'));
const PuttingOutOfYourMind = loadable(() => import('./notes/components/PuttingOutOfYourMind'));
const Quiet = loadable(() => import('./notes/components/Quiet'));
const RadicalAcceptance = loadable(() => import('./notes/components/RadicalAcceptance'));
const RadicalCandor = loadable(() => import('./notes/components/RadicalCandor'));
const RadicalHope = loadable(() => import('./notes/components/RadicalHope'));
const Range = loadable(() => import('./notes/components/Range'));
const RavingFans = loadable(() => import('./notes/components/RavingFans'));
const ReadyForAnything2 = loadable(() => import('./notes/components/ReadyForAnything2'));
const Rebirth = loadable(() => import('./notes/components/Rebirth'));
const Relentless = loadable(() => import('./notes/components/Relentless'));
const ResistingHappiness = loadable(() => import('./notes/components/ResistingHappiness'));
const Rework = loadable(() => import('./notes/components/Rework'));
// const Rexisms = loadable(() => import('./notes/components/Rexisms'));
const RichDadPoorDad = loadable(() => import('./notes/components/RichDadPoorDad'));
const RulesForLife = loadable(() => import('./notes/components/12RulesForLife'));
const Sapiens = loadable(() => import('./notes/components/Sapiens'));
const SearchInsideYourself = loadable(() => import('./notes/components/SearchInsideYourself'));
const SecretSauce = loadable(() => import('./notes/components/SecretSauce'));
const SecretsOfSandHillRoad = loadable(() => import('./notes/components/SecretsOfSandHillRoad'));
const SeeDoRepeat = loadable(() => import('./notes/components/SeeDoRepeat'));
const SelfReliance = loadable(() => import('./notes/components/SelfReliance'));
const ShapeUp = loadable(() => import('./notes/components/ShapeUp'));
const ShoeDog = loadable(() => import('./notes/components/ShoeDog'));
const ShowYourWork = loadable(() => import('./notes/components/ShowYourWork'));
const Siddhartha = loadable(() => import('./notes/components/Siddhartha'));
const SlicingPieHandbook = loadable(() => import('./notes/components/SlicingPieHandbook'));
const SmarterFasterBetter = loadable(() => import('./notes/components/SmarterFasterBetter'));
const SoGoodTheyCantIgnoreYou = loadable(() =>
  import('./notes/components/SoGoodTheyCantIgnoreYou')
);
const SolveForHappy = loadable(() => import('./notes/components/SolveForHappy'));
const Sprint = loadable(() => import('./notes/components/Sprint'));
const StartLoveRepeat = loadable(() => import('./notes/components/StartLoveRepeat'));
const StartWithWhy = loadable(() => import('./notes/components/StartWithWhy'));
const SteveJobs = loadable(() => import('./notes/components/SteveJobs'));
const StillnessIsTheKey = loadable(() => import('./notes/components/StillnessIsTheKey'));
const Storyworthy = loadable(() => import('./notes/components/Storyworthy'));
const TalkingToHumans = loadable(() => import('./notes/components/TalkingToHumans'));
const TalkLikeTed = loadable(() => import('./notes/components/TalkLikeTed'));
const TaoTeChing = loadable(() => import('./notes/components/TaoTeChing'));
const TenPercentHappier = loadable(() => import('./notes/components/TenPercentHappier'));
const The10XRule = loadable(() => import('./notes/components/The10XRule'));
const The22ImmutableLawsOfMarketing = loadable(() =>
  import('./notes/components/The22ImmutableLawsOfMarketing')
);
const The48LawsOfPower = loadable(() => import('./notes/components/The48LawsOfPower'));
const The4HourBody = loadable(() => import('./notes/components/The4HourBody'));
const The4HourChef = loadable(() => import('./notes/components/The4HourChef'));
const The4HourWorkweek = loadable(() => import('./notes/components/The4HourWorkweek'));
const The7HabitsOfHighlyEffectivePeople = loadable(() =>
  import('./notes/components/The7HabitsOfHighlyEffectivePeople')
);
const TheAdvantage = loadable(() => import('./notes/components/TheAdvantage'));
const TheAlchemist = loadable(() => import('./notes/components/TheAlchemist'));
const TheAllegoryOfTheCave = loadable(() => import('./notes/components/TheAllegoryOfTheCave'));
const TheArtOfAsking = loadable(() => import('./notes/components/TheArtOfAsking'));
const TheArtOfLearning = loadable(() => import('./notes/components/TheArtOfLearning'));
const TheArtOfStartupFundraising = loadable(() => import('./notes/components/TheArtOfStartupFundraising'));
const TheArtOfTheDeal = loadable(() => import('./notes/components/TheArtOfTheDeal'));
const TheArtOfWar = loadable(() => import('./notes/components/TheArtOfWar'));
const TheArtSpirit = loadable(() => import('./notes/components/TheArtSpirit'));
const TheAutobiographyOfAYogi = loadable(() =>
  import('./notes/components/TheAutobiographyOfAYogi')
);
const TheBigShort = loadable(() => import('./notes/components/TheBigShort'));
const TheBodyKeepsTheScore = loadable(() => import('./notes/components/TheBodyKeepsTheScore'));
const TheBulletJournalMethod = loadable(() => import('./notes/components/TheBulletJournalMethod'));
const TheCatcherInTheRye = loadable(() => import('./notes/components/TheCatcherInTheRye'));
const TheCelestineProphecy = loadable(() => import('./notes/components/TheCelestineProphecy'));
const TheChecklistManifesto = loadable(() => import('./notes/components/TheChecklistManifesto'));
const TheChinaStudy = loadable(() => import('./notes/components/TheChinaStudy'));
const TheCircle = loadable(() => import('./notes/components/TheCircle'));
const TheCleanCoder = loadable(() => import('./notes/components/TheCleanCoder'));
const TheColdStartProblem = loadable(() => import('./notes/components/TheColdStartProblem'));
const TheCourageToBeDisliked = loadable(() => import('./notes/components/TheCourageToBeDisliked'));
const TheCreativeAct = loadable(() => import('./notes/components/TheCreativeAct'));
const TheCultureCode = loadable(() => import('./notes/components/TheCultureCode'));
const TheDailyStoic = loadable(() => import('./notes/components/TheDailyStoic'));
const TheDefiningDecade = loadable(() => import('./notes/components/TheDefiningDecade'));
const TheDesignOfEverydayThings = loadable(() =>
  import('./notes/components/TheDesignOfEverydayThings')
);
const TheDip = loadable(() => import('./notes/components/TheDip'));
const TheDoorsOfPerception = loadable(() => import('./notes/components/TheDoorsOfPerception'));
const TheEffectiveExecutive = loadable(() => import('./notes/components/TheEffectiveExecutive'));
const TheElementsOfProgrammingStyle = loadable(() =>
  import('./notes/components/TheElementsOfProgrammingStyle')
);
const TheElementsOfStyle = loadable(() => import('./notes/components/TheElementsOfStyle'));
const TheEMythRevisited = loadable(() =>
  import('./notes/components/TheEMythRevisited')
);
const TheEverythingStore = loadable(() => import('./notes/components/TheEverythingStore'));
const TheFirstAndLastFreedom = loadable(() => import('./notes/components/TheFirstAndLastFreedom'));
const TheFiveDysfunctionsOfATeam = loadable(() => import('./notes/components/TheFiveDysfunctionsOfATeam'));
const TheFourAgreements = loadable(() => import('./notes/components/TheFourAgreements'));
const TheFourAgreements2 = loadable(() => import('./notes/components/TheFourAgreements2'));
const TheFourStepsToEpiphany = loadable(() => import('./notes/components/TheFourStepsToEpiphany'));
const TheGenerousLeader = loadable(() => import('./notes/components/TheGenerousLeader'));
const TheGiver = loadable(() => import('./notes/components/TheGiver'));
const TheGoal = loadable(() => import('./notes/components/TheGoal'));
const TheGoGiver = loadable(() => import('./notes/components/TheGoGiver'));
const TheGreatestSalesmanInTheWorld = loadable(() => import('./notes/components/TheGreatestSalesmanInTheWorld'));
const TheGreatGatsby = loadable(() => import('./notes/components/TheGreatGatsby'));
const TheHappinessAdvantage = loadable(() =>
  import('./notes/components/TheHappinessAdvantage')
);
const TheHardThingAboutHardThings = loadable(() =>
  import('./notes/components/TheHardThingAboutHardThings')
);
const TheHitchhikersGuideToTheGalaxy = loadable(() =>
  import('./notes/components/TheHitchhikersGuideToTheGalaxy')
);
const TheHolyBible = loadable(() => import('./notes/components/TheHolyBible'));
const TheInnerGameOfTennis = loadable(() => import('./notes/components/TheInnerGameOfTennis'));
const TheInnovators = loadable(() => import('./notes/components/TheInnovators'));
const TheInnovatorsDilemma = loadable(() => import('./notes/components/TheInnovatorsDilemma'));
const TheIntelligentInvestor = loadable(() => import('./notes/components/TheIntelligentInvestor'));
const TheIOSInterviewGuide = loadable(() => import('./notes/components/TheIOSInterviewGuide'));
const TheLastLecture = loadable(() => import('./notes/components/TheLastLecture'));
const TheLeanStartup = loadable(() => import('./notes/components/TheLeanStartup'));
const TheLittlePrince = loadable(() => import('./notes/components/TheLittlePrince'));
const TheMagicOfThinkingBig = loadable(() => import('./notes/components/TheMagicOfThinkingBig'));
const TheMarketingBlueprint = loadable(() => import('./notes/components/TheMarketingBlueprint'));
const TheMartian = loadable(() => import('./notes/components/TheMartian'));
const TheMillionaireNextDoor = loadable(() => import('./notes/components/TheMillionaireNextDoor'));
const TheMomTest = loadable(() => import('./notes/components/TheMomTest'));
const TheMonkWhoSoldHisFerrari = loadable(() =>
  import('./notes/components/TheMonkWhoSoldHisFerrari')
);
const TheMountainIsYou = loadable(() => import('./notes/components/TheMountainIsYou'));
const TheObstacleIsTheWay = loadable(() => import('./notes/components/TheObstacleIsTheWay'));
const TheOldManAndTheSea = loadable(() => import('./notes/components/TheOldManAndTheSea'));
const TheOneMinuteManager = loadable(() => import('./notes/components/TheOneMinuteManager'));
const TheOneThing = loadable(() => import('./notes/components/TheOneThing'));
const ThePowerLaw = loadable(() => import('./notes/components/ThePowerLaw'));
const ThePowerOfHabit = loadable(() => import('./notes/components/ThePowerOfHabit'));
const ThePowerOfMyth = loadable(() => import('./notes/components/ThePowerOfMyth'));
const ThePowerOfNow = loadable(() => import('./notes/components/ThePowerOfNow'));
const ThePowerOfPositiveThinking = loadable(() =>
  import('./notes/components/ThePowerOfPositiveThinking')
);
const ThePowerOfWritingItDown = loadable(() => import('./notes/components/ThePowerOfWritingItDown'));
const ThePracticeOfProgramming = loadable(() =>
  import('./notes/components/ThePracticeOfProgramming')
);
const ThePragmaticProgrammer = loadable(() => import('./notes/components/ThePragmaticProgrammer'));
const TheProphet = loadable(() => import('./notes/components/TheProphet'));
const ThePurposeDrivenLife = loadable(() => import('./notes/components/ThePurposeDrivenLife'));
const TheRemainsOfTheDay = loadable(() => import('./notes/components/TheRemainsOfTheDay'));
const TheRhythmOfLife = loadable(() => import('./notes/components/TheRhythmOfLife'));
const TheRichestManInBabylon = loadable(() => import('./notes/components/TheRichestManInBabylon'));
const TheRoad = loadable(() => import('./notes/components/TheRoad'));
const TheRoadToCharacter = loadable(() => import('./notes/components/TheRoadToCharacter'));
const TheRuthlessEliminationOfHurry = loadable(() => import('./notes/components/TheRuthlessEliminationOfHurry'));
const TheScienceOfHitting = loadable(() => import('./notes/components/TheScienceOfHitting'));
const TheScoreTakesCareOfItself = loadable(() =>
  import('./notes/components/TheScoreTakesCareOfItself')
);
const TheSearchForTruth = loadable(() => import('./notes/components/TheSearchForTruth'));
const TheSecret = loadable(() => import('./notes/components/TheSecret'));
const TheSevenSpiritualLawsOfSuccess = loadable(() =>
  import('./notes/components/TheSevenSpiritualLawsOfSuccess')
);
const TheStoicChallenge = loadable(() => import('./notes/components/TheStoicChallenge'));
const TheStrangestSecret = loadable(() => import('./notes/components/TheStrangestSecret'));
const TheSubtleArtOfNotGivingAFck = loadable(() =>
  import('./notes/components/TheSubtleArtOfNotGivingAFck')
);
const TheSurrenderExperiment = loadable(() => import('./notes/components/TheSurrenderExperiment'));
const TheSwerve = loadable(() => import('./notes/components/TheSwerve'));
const TheTaoOfHealthSexAndLongevity = loadable(() => import('./notes/components/TheTaoOfHealthSexAndLongevity'));
const TheTaoOfPooh = loadable(() => import('./notes/components/TheTaoOfPooh'));
const TheTaoOfWu = loadable(() => import('./notes/components/TheTaoOfWu'));
const TheTB12Method = loadable(() => import('./notes/components/TheTB12Method'));
const TheThirdDoor = loadable(() => import('./notes/components/TheThirdDoor'));
const TheTimelessWayOfBuilding = loadable(() =>
  import('./notes/components/TheTimelessWayOfBuilding')
);
const TheTippingPoint = loadable(() => import('./notes/components/TheTippingPoint'));
const TheUltimateAnabolicCookbook = loadable(() =>
  import('./notes/components/TheUltimateAnabolicCookbook')
);
const TheUnbearableLightnessOfBeing = loadable(() =>
  import('./notes/components/TheUnbearableLightnessOfBeing')
);
const TheUntetheredSoul = loadable(() => import('./notes/components/TheUntetheredSoul'));
const TheVirginWay = loadable(() => import('./notes/components/TheVirginWay'));
const TheWarOfArt = loadable(() => import('./notes/components/TheWarOfArt'));
const TheWayOfTheSuperiorMan = loadable(() => import('./notes/components/TheWayOfTheSuperiorMan'));
const TheWayOfZen = loadable(() => import('./notes/components/TheWayOfZen'));
const TheWayToLove = loadable(() => import('./notes/components/TheWayToLove'));
const TheWisdomOfLife = loadable(() => import('./notes/components/TheWisdomOfLife'));
const TheWrightBrothers = loadable(() => import('./notes/components/TheWrightBrothers'));
const ThingsFallApart = loadable(() => import('./notes/components/ThingsFallApart'));
const ThinkAndGrowRich = loadable(() => import('./notes/components/ThinkAndGrowRich'));
const ThinkingFastAndSlow = loadable(() => import('./notes/components/ThinkingFastAndSlow'));
const ThinkOnTheseThings = loadable(() => import('./notes/components/ThinkOnTheseThings'));
const ThisIsMarketing = loadable(() => import('./notes/components/ThisIsMarketing'));
const TinyHabits = loadable(() => import('./notes/components/TinyHabits'));
const TitlesRuinEverything = loadable(() => import('./notes/components/TitlesRuinEverything'));
const ToKillAMockingBird = loadable(() => import('./notes/components/ToKillAMockingBird'));
const ToolsOfTitans = loadable(() => import('./notes/components/ToolsOfTitans'));
const Traction = loadable(() => import('./notes/components/Traction'));
const Tribe = loadable(() => import('./notes/components/Tribe'));
const TrustMeImLying = loadable(() => import('./notes/components/TrustMeImLying'));
const TuesdaysWithMorrie = loadable(() => import('./notes/components/TuesdaysWithMorrie'));
const UnderstandingHumanNature = loadable(() =>
  import('./notes/components/UnderstandingHumanNature')
);
const Vagabonding = loadable(() => import('./notes/components/Vagabonding'));
const VentureDeals = loadable(() => import('./notes/components/VentureDeals'));
const WabiSabi = loadable(() => import('./notes/components/WabiSabi'));
const WakingUp = loadable(() => import('./notes/components/WakingUp'));
const WhatITalkAboutWhenITalkAboutRunning = loadable(() => import('./notes/components/WhatITalkAboutWhenITalkAboutRunning'));
const WhenThingsFallApart = loadable(() => import('./notes/components/WhenThingsFallApart'));
const WhereTheCrawdadsSing = loadable(() => import('./notes/components/WhereTheCrawdadsSing'));
const WhereverYouGoThereYouAre = loadable(() => import('./notes/components/WhereverYouGoThereYouAre'));
const White = loadable(() => import('./notes/components/White'));
const WhiteFragility = loadable(() => import('./notes/components/WhiteFragility'));
const Who = loadable(() => import('./notes/components/Who'));
const WhoMovedMyCheese = loadable(() => import('./notes/components/WhoMovedMyCheese'));
const WhyWerePolarized = loadable(() => import('./notes/components/WhyWerePolarized'));
const WhyWeSleep = loadable(() => import('./notes/components/WhyWeSleep'));
const Willpower = loadable(() => import('./notes/components/Willpower'));
const Wooden = loadable(() => import('./notes/components/Wooden'));
const WriteItDownMakeItHappen = loadable(() => import('./notes/components/WriteItDownMakeItHappen'));
const ZenMindBeginnersMind = loadable(() => import('./notes/components/ZenMindBeginnersMind'));
const ZenToDone = loadable(() => import('./notes/components/ZenToDone'));
const ZeroToOne = loadable(() => import('./notes/components/ZeroToOne'));

export default (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/about" component={AboutPage} />
    <Route exact path="/apps" component={AppsPage} />
    {apps.map(app => <Route key={app.path} exact path={app.path} component={AppPage} />)}
    <Route exact path="/contact" component={ContactPage} />
    <Route exact path="/notes" component={NotesPage} />
    <Route exact path="/notes/12-rules-for-life" component={RulesForLife} />
    <Route exact path="/notes/177-mental-toughness-secrets-of-the-world-class" component={MentalToughnessSecretsOfTheWorldClass} />
    <Route exact path="/notes/a-brief-history-of-time" component={ABriefHistoryOfTime} />
    <Route exact path="/notes/a-curious-mind" component={ACuriousMind} />
    <Route exact path="/notes/a-guide-to-the-good-life" component={AGuideToTheGoodLife} />
    <Route exact path="/notes/algorithms-to-live-by" component={AlgorithmsToLiveBy} />
    <Route exact path="/notes/above-the-line" component={AboveTheLine} />
    <Route exact path="/notes/alibaba" component={Alibaba} />
    <Route
      exact
      path="/notes/a-million-miles-in-a-thousand-years"
      component={AMillionMilesInAThousandYears}
    />
    <Route exact path="/notes/amp-it-up" component={AmpItUp} />
    <Route exact path="/notes/amusing-ourselves-to-death" component={AmusingOurselvesToDeath} />
    <Route exact path="/notes/a-new-earth" component={ANewEarth} />
    <Route exact path="/notes/antifragile" component={Antifragile} />
    <Route exact path="/notes/alreadyFree" component={AlreadyFree} />
    <Route exact path="/notes/anything-you-want" component={AnythingYouWant} />
    <Route exact path="/notes/as-a-man-thinketh" component={AsAManThinketh} />
    <Route exact path="/notes/as-a-man-thinketh-2" component={AsAManThinketh2} />
    <Route exact path="/notes/ask-and-it-is-given" component={AskAndItIsGiven} />
    <Route
      exact
      path="/notes/a-short-history-of-nearly-everything"
      component={AShortHistoryOfNearlyEverything}
    />
    <Route exact path="/notes/atlas-shrugged" component={AtlasShrugged} />
    <Route exact path="/notes/atomic-habits" component={AtomicHabits} />
    <Route exact path="/notes/attached" component={Attached} />
    <Route exact path="/notes/awaken-the-giant-within" component={AwakenTheGiantWithin} />
    <Route exact path="/notes/awareness" component={Awareness} />
    <Route exact path="/notes/backable" component={Backable} />
    <Route exact path="/notes/barbarian-days" component={BarbarianDays} />
    <Route exact path="/notes/becoming-wise" component={BecomingWise} />
    <Route exact path="/notes/benjamin-franklin" component={BenjaminFranklin} />
    <Route exact path="/notes/bhagavad-gita" component={BhagavadGita} />
    <Route exact path="/notes/big-bets" component={BigBets} />
    <Route exact path="/notes/big-magic" component={BigMagic} />
    <Route exact path="/notes/bird-by-bird" component={BirdByBird} />
    <Route exact path="/notes/blue-ocean-strategy" component={BlueOceanStrategy} />
    <Route exact path="/notes/brave-new-world" component={BraveNewWorld} />
    <Route exact path="/notes/breakthrough-advertising" component={BreakthroughAdvertising} />
    <Route exact path="/notes/buddhism-without-beliefs" component={BuddhismWithoutBeliefs} />
    <Route exact path="/notes/building-a-second-brain" component={BuildingASecondBrain} />
    <Route exact path="/notes/building-a-storybrand" component={BuildingAStoryBrand} />
    <Route exact path="/notes/built-to-last" component={BuiltToLast} />
    <Route exact path="/notes/calypso" component={Calypso} />
    <Route exact path="/notes/cant-hurt-me" component={CantHurtMe} />
    <Route exact path="/notes/choose-yourself" component={ChooseYourself} />
    <Route exact path="/notes/clean-code" component={CleanCode} />
    <Route exact path="/notes/code-complete" component={CodeComplete} />
    <Route exact path="/notes/common-sense" component={CommonSense} />
    <Route exact path="/notes/conceptual-blockbusting" component={ConceptualBlockbusting} />
    <Route exact path="/notes/conscious" component={Conscious} />
    <Route exact path="/notes/contagious" component={Contagious} />
    <Route exact path="/notes/courage-is-calling" component={CourageIsCalling} />
    <Route
      exact
      path="/notes/cracking-the-coding-interview"
      component={CrackingTheCodingInterview}
    />
    <Route exact path="/notes/creativity-inc" component={CreativityInc} />
    <Route exact path="/notes/crossing-the-chasm" component={CrossingTheChasm} />
    <Route exact path="/notes/crush-it" component={CrushIt} />
    <Route exact path="/notes/css-secrets" component={CssSecrets} />
    <Route exact path="/notes/dare-to-lead" component={DareToLead} />
    <Route exact path="/notes/decoded" component={Decoded} />
    <Route exact path="/notes/deep-work" component={DeepWork} />
    <Route exact path="/notes/delivering-happiness" component={DeliveringHappiness} />
    <Route exact path="/notes/design-as-art" component={DesignAsArt} />
    <Route exact path="/notes/design-patterns-by-tutorials" component={DesignPatternsByTutorials} />
    <Route exact path="/notes/dont-lose-your-mind-lose-your-weight" component={DontLoseYourMindLoseYourWeight} />
    <Route exact path="/notes/dont-make-me-think" component={DontMakeMeThink} />
    <Route exact path="/notes/dont-worry-make-money" component={DontWorryMakeMoney} />
    <Route exact path="/notes/dopamine-nation" component={DopamineNation} />
    <Route exact path="/notes/dropping-ashes-on-the-buddha" component={DroppingAshesOnTheBuddha} />
    <Route exact path="/notes/effective-objective-c-2-0" component={EffectiveObjectiveC20} />
    <Route exact path="/notes/ego-is-the-enemy" component={EgoIsTheEnemy} />
    <Route exact path="/notes/elon-musk" component={ElonMusk} />
    <Route exact path="/notes/eloquent-javascript" component={EloquentJavaScript} />
    <Route exact path="/notes/emotional-intelligence" component={EmotionalIntelligence} />
    <Route exact path="/notes/emotional-intelligence-2-0" component={EmotionalIntelligence20} />
    <Route exact path="/notes/enchiridion" component={Enchiridion} />
    <Route exact path="/notes/essentialism" component={Essentialism} />
    <Route exact path="/notes/everybody-writes" component={EverybodyWrites} />
    <Route exact path="/notes/exit-right" component={ExitRight} />
    <Route exact path="/notes/expert-secrets" component={ExpertSecrets} />
    <Route exact path="/notes/extraordinary-golf" component={ExtraordinaryGolf} />
    <Route exact path="/notes/fat-loss-forever" component={FatLossForever} />
    <Route exact path="/notes/fit-for-purpose" component={FitForPurpose} />
    <Route exact path="/notes/flash-boys" component={FlashBoys} />
    <Route exact path="/notes/flow" component={Flow} />
    <Route exact path="/notes/four-thousand-weeks" component={FourThousandWeeks} />
    <Route exact path="/notes/getting-real" component={GettingReal} />
    <Route exact path="/notes/getting-things-done" component={GettingThingsDone} />
    <Route exact path="/notes/getting-to-yes" component={GettingToYes} />
    <Route exact path="/notes/good-profit" component={GoodProfit} />
    <Route exact path="/notes/good-to-great" component={GoodToGreat} />
    <Route exact path="/notes/greenlights" component={Greenlights} />
    <Route
      exact
      path="/notes/grid-systems-in-graphic-design"
      component={GridSystemsInGraphicDesign}
    />
    <Route exact path="/notes/grit" component={Grit} />
    <Route exact path="/notes/hatching-twitter" component={HatchingTwitter} />
    <Route exact path="/notes/healing-back-pain" component={HealingBackPain} />
    <Route exact path="/notes/high-output-management" component={HighOutputManagement} />
    <Route exact path="/notes/hillbilly-elegy" component={HillbillyElegy} />
    <Route exact path="/notes/home-is-where-we-start-from" component={HomeIsWhereWeStartFrom} />
    <Route exact path="/notes/hooked" component={Hooked} />
    <Route exact path="/notes/how-google-works" component={HowGoogleWorks} />
    <Route exact path="/notes/how-not-to-die" component={HowNotToDie} />
    <Route exact path="/notes/how-to-get-rich" component={HowToGetRich} />
    <Route exact path="/notes/how-to-hustle-and-win" component={HowToHustleAndWin} />
    <Route exact path="/notes/how-to-read-a-book" component={HowToReadABook} />
    <Route
      exact
      path="/notes/how-to-sell-anything-to-anyone"
      component={HowToSellAnythingToAnyone}
    />
    <Route
      exact
      path="/notes/how-to-stop-worrying-and-start-living"
      component={HowToStopWorryingAndStartLiving}
    />
    <Route
      exact
      path="/notes/how-to-take-smart-notes"
      component={HowToTakeSmartNotes}
    />
    <Route
      exact
      path="/notes/how-to-win-friends-and-influence-people"
      component={HowToWinFriendsAndInfluencePeople}
    />
    <Route
      exact
      path="/notes/how-will-you-measure-your-life"
      component={HowWillYouMeasureYourLife}
    />
    <Route exact path="/notes/html-css" component={HtmlCss} />
    <Route exact path="/notes/ikigai" component={Ikigai} />
    <Route exact path="/notes/infinite-jest" component={InfiniteJest} />
    <Route exact path="/notes/influence" component={Influence} />
    <Route exact path="/notes/in-pieces" component={InPieces} />
    <Route exact path="/notes/in-praise-of-shadows" component={InPraiseOfShadows} />
    <Route exact path="/notes/in-search-of-excellence" component={InSearchOfExcellence} />
    <Route exact path="/notes/insight" component={Insight} />
    <Route exact path="/notes/inspired" component={Inspired} />
    <Route exact path="/notes/introduction-to-algorithms" component={IntroductionToAlgorithms} />
    <Route exact path="/notes/ios-programming" component={IOSProgramming} />
    <Route
      exact
      path="/notes/javascript-the-definitive-guide"
      component={JavaScriptTheDefinitiveGuide}
    />
    <Route exact path="/notes/jonathan-livingston-seagull" component={JonathanLivingstonSeagull} />
    <Route
      exact
      path="/notes/learning-cocoa-with-objective-c"
      component={LearningCocoaWithObjectiveC}
    />
    <Route exact path="/notes/learning-nodejs" component={LearningNodejs} />
    <Route exact path="/notes/letters-from-a-stoic" component={LettersFromAStoic} />
    <Route exact path="/notes/lone-survivor" component={LoneSurvivor} />
    <Route exact path="/notes/lord-of-the-flies" component={LordOfTheFlies} />
    <Route exact path="/notes/love-yourself-like-your-life-depends-on-it" component={LoveYourselfLikeYourLifeDependsOnIt} />
    <Route exact path="/notes/made-in-america" component={MadeInAmerica} />
    <Route exact path="/notes/made-to-stick" component={MadeToStick} />
    <Route exact path="/notes/make-time" component={MakeTime} />
    <Route exact path="/notes/make-your-bed" component={MakeYourBed} />
    <Route exact path="/notes/make-your-mark" component={MakeYourMark} />
    <Route exact path="/notes/mans-search-for-meaning" component={MansSearchForMeaning} />
    <Route exact path="/notes/masters-of-doom" component={MastersOfDoom} />
    <Route exact path="/notes/meditations" component={Meditations} />
    <Route exact path="/notes/memoirs-of-a-geisha" component={MemoirsOfAGeisha} />
    <Route exact path="/notes/mindset" component={Mindset} />
    <Route exact path="/notes/mythical-man-month" component={MythicalManMonth} />
    <Route exact path="/notes/never-eat-alone" component={NeverEatAlone} />
    <Route exact path="/notes/never-split-the-difference" component={NeverSplitTheDifference} />
    <Route exact path="/notes/no-more-mr-nice-guy" component={NoMoreMrNiceGuy} />
    <Route exact path="/notes/objective-c-programming" component={ObjectiveCProgramming} />
    <Route exact path="/notes/object-oriented-javascript" component={ObjectOrientedJavaScript} />
    <Route exact path="/notes/off-the-beaten-trail" component={OffTheBeatenTrail} />
    <Route exact path="/notes/on-the-shortness-of-life" component={OnTheShortnessOfLife} />
    <Route exact path="/notes/originals" component={Originals} />
    <Route exact path="/notes/outliers" component={Outliers} />
    <Route exact path="/notes/outwitting-the-devil" component={OutwittingTheDevil} />
    <Route exact path="/notes/peace-is-every-step" component={PeaceIsEveryStep} />
    <Route exact path="/notes/perennial-seller" component={PerennialSeller} />
    <Route exact path="/notes/perfectly-yourself" component={PerfectlyYourself} />
    <Route exact path="/notes/plant-based-high-protein-cookbook" component={PlantBasedHighProteinCookbook} />
    <Route exact path="/notes/play-bigger" component={PlayBigger} />
    <Route exact path="/notes/poor-charlies-almanack" component={PoorCharliesAlmanack} />
    <Route exact path="/notes/pour-your-heart-into-it" component={PourYourHeartIntoIt} />
    <Route exact path="/notes/principles" component={Principles} />
    <Route exact path="/notes/product-led-growth" component={ProductLedGrowth} />
    <Route
      exact
      path="/notes/programming-interviews-exposed"
      component={ProgrammingInterviewsExposed}
    />
    <Route exact path="/notes/programming-pearls" component={ProgrammingPearls} />
    <Route exact path="/notes/purple-cow" component={PurpleCow} />
    <Route exact path="/notes/putting-out-of-your-mind" component={PuttingOutOfYourMind} />
    <Route exact path="/notes/quiet" component={Quiet} />
    <Route exact path="/notes/radical-acceptance" component={RadicalAcceptance} />
    <Route exact path="/notes/radical-candor" component={RadicalCandor} />
    <Route exact path="/notes/radical-hope" component={RadicalHope} />
    <Route exact path="/notes/range" component={Range} />
    <Route exact path="/notes/raving-fans" component={RavingFans} />
    <Route exact path="/notes/ready-for-anything" component={ReadyForAnything2} />
    <Route exact path="/notes/rebirth" component={Rebirth} />
    <Route exact path="/notes/relentless" component={Relentless} />
    <Route exact path="/notes/resisting-happiness" component={ResistingHappiness} />
    <Route exact path="/notes/rework" component={Rework} />
    <Route exact path="/notes/rich-dad-poor-dad" component={RichDadPoorDad} />
    <Route exact path="/notes/sapiens" component={Sapiens} />
    <Route exact path="/notes/search-inside-yourself" component={SearchInsideYourself} />
    <Route exact path="/notes/secret-sauce" component={SecretSauce} />
    <Route exact path="/notes/secrets-of-sand-hill-road" component={SecretsOfSandHillRoad} />
    <Route exact path="/notes/self-reliance" component={SelfReliance} />
    <Route exact path="/notes/see-do-repeat" component={SeeDoRepeat} />
    <Route exact path="/notes/shape-up" component={ShapeUp} />
    <Route exact path="/notes/shoe-dog" component={ShoeDog} />
    <Route exact path="/notes/show-your-work" component={ShowYourWork} />
    <Route exact path="/notes/siddhartha" component={Siddhartha} />
    <Route exact path="/notes/slicing-pie-handbook" component={SlicingPieHandbook} />
    <Route exact path="/notes/smarter-faster-better" component={SmarterFasterBetter} />
    <Route exact path="/notes/so-good-they-cant-ignore-you" component={SoGoodTheyCantIgnoreYou} />
    <Route exact path="/notes/solve-for-happy" component={SolveForHappy} />
    <Route exact path="/notes/sprint" component={Sprint} />
    <Route exact path="/notes/start-love-repeat" component={StartLoveRepeat} />
    <Route exact path="/notes/start-with-why" component={StartWithWhy} />
    <Route exact path="/notes/steve-jobs" component={SteveJobs} />
    <Route exact path="/notes/stillness-is-the-key" component={StillnessIsTheKey} />
    <Route exact path="/notes/storyworthy" component={Storyworthy} />
    <Route exact path="/notes/talking-to-humans" component={TalkingToHumans} />
    <Route exact path="/notes/talk-like-ted" component={TalkLikeTed} />
    <Route exact path="/notes/tao-te-ching" component={TaoTeChing} />
    <Route exact path="/notes/ten-percent-happier" component={TenPercentHappier} />
    <Route exact path="/notes/the-10x-rule" component={The10XRule} />
    <Route
      exact
      path="/notes/the-22-immutable-laws-of-marketing"
      component={The22ImmutableLawsOfMarketing}
    />
    <Route exact path="/notes/the-48-laws-of-power" component={The48LawsOfPower} />
    <Route exact path="/notes/the-4-hour-body" component={The4HourBody} />
    <Route exact path="/notes/the-4-hour-chef" component={The4HourChef} />
    <Route exact path="/notes/the-4-hour-workweek" component={The4HourWorkweek} />
    <Route
      exact
      path="/notes/the-7-habits-of-highly-effective-people"
      component={The7HabitsOfHighlyEffectivePeople}
    />
    <Route exact path="/notes/the-advantage" component={TheAdvantage} />
    <Route exact path="/notes/the-alchemist" component={TheAlchemist} />
    <Route exact path="/notes/the-allegory-of-the-cave" component={TheAllegoryOfTheCave} />
    <Route exact path="/notes/the-art-of-asking" component={TheArtOfAsking} />
    <Route exact path="/notes/the-art-of-learning" component={TheArtOfLearning} />
    <Route exact path="/notes/the-art-of-startup-fundraising" component={TheArtOfStartupFundraising} />
    <Route exact path="/notes/the-art-of-the-deal" component={TheArtOfTheDeal} />
    <Route exact path="/notes/the-art-of-war" component={TheArtOfWar} />
    <Route exact path="/notes/the-art-spirit" component={TheArtSpirit} />
    <Route exact path="/notes/the-autobiography-of-a-yogi" component={TheAutobiographyOfAYogi} />
    <Route exact path="/notes/the-big-short" component={TheBigShort} />
    <Route exact path="/notes/the-body-keeps-the-score" component={TheBodyKeepsTheScore} />
    <Route exact path="/notes/the-bullet-journal-method" component={TheBulletJournalMethod} />
    <Route exact path="/notes/the-catcher-in-the-rye" component={TheCatcherInTheRye} />
    <Route exact path="/notes/the-celestine-prophecy" component={TheCelestineProphecy} />
    <Route exact path="/notes/the-checklist-manifesto" component={TheChecklistManifesto} />
    <Route exact path="/notes/the-china-study" component={TheChinaStudy} />
    <Route exact path="/notes/the-circle" component={TheCircle} />
    <Route exact path="/notes/the-clean-coder" component={TheCleanCoder} />
    <Route exact path="/notes/the-cold-start-problem" component={TheColdStartProblem} />
    <Route exact path="/notes/the-courage-to-be-disliked" component={TheCourageToBeDisliked} />
    <Route exact path="/notes/the-creative-act" component={TheCreativeAct} />
    <Route exact path="/notes/the-culture-code" component={TheCultureCode} />
    <Route exact path="/notes/the-daily-stoic" component={TheDailyStoic} />
    <Route exact path="/notes/the-defining-decade" component={TheDefiningDecade} />
    <Route
      exact
      path="/notes/the-design-of-everyday-things"
      component={TheDesignOfEverydayThings}
    />
    <Route exact path="/notes/the-dip" component={TheDip} />
    <Route exact path="/notes/the-doors-of-perception" component={TheDoorsOfPerception} />
    <Route exact path="/notes/the-effective-executives" component={TheEffectiveExecutive} />
    <Route
      exact
      path="/notes/the-elements-of-programming-style"
      component={TheElementsOfProgrammingStyle}
    />
    <Route exact path="/notes/the-elements-of-style" component={TheElementsOfStyle} />
    <Route exact path="/notes/the-e-myth-revisited" component={TheEMythRevisited} />
    <Route exact path="/notes/the-everything-store" component={TheEverythingStore} />
    <Route exact path="/notes/the-first-and-last-freedom" component={TheFirstAndLastFreedom} />
    <Route exact path="/notes/the-five-dysfunctions-of-a-team" component={TheFiveDysfunctionsOfATeam} />
    <Route exact path="/notes/the-four-agreements" component={TheFourAgreements} />
    <Route exact path="/notes/the-four-agreements-2" component={TheFourAgreements2} />
    <Route exact path="/notes/the-four-steps-to-epiphany" component={TheFourStepsToEpiphany} />
    <Route exact path="/notes/the-generous-leader" component={TheGenerousLeader} />
    <Route exact path="/notes/the-giver" component={TheGiver} />
    <Route exact path="/notes/the-goal" component={TheGoal} />
    <Route exact path="/notes/the-go-giver" component={TheGoGiver} />
    <Route exact path="/notes/the-greatest-salesman-in-the-world" component={TheGreatestSalesmanInTheWorld} />
    <Route exact path="/notes/the-happiness-advantage" component={TheHappinessAdvantage} />
    <Route exact path="/notes/the-great-gatsby" component={TheGreatGatsby} />
    <Route
      exact
      path="/notes/the-hard-thing-about-hard-things"
      component={TheHardThingAboutHardThings}
    />
    <Route
      exact
      path="/notes/the-hitchhikers-guide-to-the-galaxy"
      component={TheHitchhikersGuideToTheGalaxy}
    />
    <Route exact path="/notes/the-holy-bible" component={TheHolyBible} />
    <Route exact path="/notes/the-inner-game-of-tennis" component={TheInnerGameOfTennis} />
    <Route exact path="/notes/the-innovators" component={TheInnovators} />
    <Route exact path="/notes/the-innovators-dilemma" component={TheInnovatorsDilemma} />
    <Route exact path="/notes/the-intelligent-investor" component={TheIntelligentInvestor} />
    <Route exact path="/notes/the-ios-interview-guide" component={TheIOSInterviewGuide} />
    <Route exact path="/notes/the-last-lecture" component={TheLastLecture} />
    <Route exact path="/notes/the-lean-startup" component={TheLeanStartup} />
    <Route exact path="/notes/the-little-prince" component={TheLittlePrince} />
    <Route exact path="/notes/the-magic-of-thinking-big" component={TheMagicOfThinkingBig} />
    <Route exact path="/notes/the-marketing-blueprint" component={TheMarketingBlueprint} />
    <Route exact path="/notes/the-martian" component={TheMartian} />
    <Route exact path="/notes/the-millionaire-next-door" component={TheMillionaireNextDoor} />
    <Route exact path="/notes/the-mom-test" component={TheMomTest} />
    <Route exact path="/notes/the-monk-who-sold-his-ferrari" component={TheMonkWhoSoldHisFerrari} />
    <Route exact path="/notes/the-mountain-is-you" component={TheMountainIsYou} />
    <Route exact path="/notes/the-old-man-and-the-sea" component={TheOldManAndTheSea} />
    <Route exact path="/notes/the-obstacle-is-the-way" component={TheObstacleIsTheWay} />
    <Route exact path="/notes/the-one-minute-manager" component={TheOneMinuteManager} />
    <Route exact path="/notes/the-one-thing" component={TheOneThing} />
    <Route exact path="/notes/the-power-law" component={ThePowerLaw} />
    <Route exact path="/notes/the-power-of-habit" component={ThePowerOfHabit} />
    <Route exact path="/notes/the-power-of-myth" component={ThePowerOfMyth} />
    <Route exact path="/notes/the-power-of-now" component={ThePowerOfNow} />
    <Route
      exact
      path="/notes/the-power-of-positive-thinking"
      component={ThePowerOfPositiveThinking}
    />
    <Route exact path="/notes/the-power-of-writing-it-down" component={ThePowerOfWritingItDown} />
    <Route exact path="/notes/the-practice-of-programming" component={ThePracticeOfProgramming} />
    <Route exact path="/notes/the-pragmatic-programmer" component={ThePragmaticProgrammer} />
    <Route exact path="/notes/the-prophet" component={TheProphet} />
    <Route exact path="/notes/the-purpose-driven-life" component={ThePurposeDrivenLife} />
    <Route exact path="/notes/the-remains-of-the-day" component={TheRemainsOfTheDay} />
    <Route exact path="/notes/the-rhythm-of-life" component={TheRhythmOfLife} />
    <Route exact path="/notes/the-richest-man-in-babylon" component={TheRichestManInBabylon} />
    <Route exact path="/notes/the-road" component={TheRoad} />
    <Route exact path="/notes/the-road-to-character" component={TheRoadToCharacter} />
    <Route exact path="/notes/the-ruthless-elimination-of-hurry" component={TheRuthlessEliminationOfHurry} />
    <Route exact path="/notes/the-science-of-hitting" component={TheScienceOfHitting} />
    <Route
      exact
      path="/notes/the-score-takes-care-of-itself"
      component={TheScoreTakesCareOfItself}
    />
    <Route exact path="/notes/the-search-for-truth" component={TheSearchForTruth} />
    <Route exact path="/notes/the-secret" component={TheSecret} />
    <Route
      exact
      path="/notes/the-seven-spiritual-laws-of-success"
      component={TheSevenSpiritualLawsOfSuccess}
    />
    <Route exact path="/notes/the-stoic-challenge" component={TheStoicChallenge} />
    <Route exact path="/notes/the-strangest-secret" component={TheStrangestSecret} />
    <Route
      exact
      path="/notes/the-subtle-art-of-not-giving-a-fuck"
      component={TheSubtleArtOfNotGivingAFck}
    />
    <Route exact path="/notes/the-surrender-experiment" component={TheSurrenderExperiment} />
    <Route exact path="/notes/the-swerve" component={TheSwerve} />
    <Route exact path="/notes/the-tao-of-health-sex-and-longevity" component={TheTaoOfHealthSexAndLongevity} />
    <Route exact path="/notes/the-tao-of-pooh" component={TheTaoOfPooh} />
    <Route exact path="/notes/the-tao-of-wu" component={TheTaoOfWu} />
    <Route exact path="/notes/the-tb12-method" component={TheTB12Method} />
    <Route exact path="/notes/the-third-door" component={TheThirdDoor} />
    <Route exact path="/notes/the-timeless-way-of-building" component={TheTimelessWayOfBuilding} />
    <Route exact path="/notes/the-tipping-point" component={TheTippingPoint} />
    <Route exact path="/notes/the-ultimate-anabolic-cookbook" component={TheUltimateAnabolicCookbook} />
    <Route
      exact
      path="/notes/the-unbearable-lightness-of-being"
      component={TheUnbearableLightnessOfBeing}
    />
    <Route exact path="/notes/the-untethered-soul" component={TheUntetheredSoul} />
    <Route exact path="/notes/the-virgin-way" component={TheVirginWay} />
    <Route exact path="/notes/the-war-of-art" component={TheWarOfArt} />
    <Route exact path="/notes/the-way-of-the-superior-man" component={TheWayOfTheSuperiorMan} />
    <Route exact path="/notes/the-way-of-zen" component={TheWayOfZen} />
    <Route exact path="/notes/the-way-to-love" component={TheWayToLove} />
    <Route exact path="/notes/the-wisdom-of-life" component={TheWisdomOfLife} />
    <Route exact path="/notes/the-wright-brothers" component={TheWrightBrothers} />
    <Route exact path="/notes/things-fall-apart" component={ThingsFallApart} />
    <Route exact path="/notes/think-and-grow-rich" component={ThinkAndGrowRich} />
    <Route exact path="/notes/thinking-fast-and-slow" component={ThinkingFastAndSlow} />
    <Route exact path="/notes/think-on-these-things" component={ThinkOnTheseThings} />
    <Route exact path="/notes/this-is-marketing" component={ThisIsMarketing} />
    <Route exact path="/notes/tiny-habits" component={TinyHabits} />
    <Route exact path="/notes/titles-ruin-everything" component={TitlesRuinEverything} />
    <Route exact path="/notes/to-kill-a-mocking-bird" component={ToKillAMockingBird} />
    <Route exact path="/notes/tools-of-titans" component={ToolsOfTitans} />
    <Route exact path="/notes/traction" component={Traction} />
    <Route exact path="/notes/tribe" component={Tribe} />
    <Route exact path="/notes/trust-me-im-lying" component={TrustMeImLying} />
    <Route exact path="/notes/tuesdays-with-morrie" component={TuesdaysWithMorrie} />
    <Route exact path="/notes/understanding-human-nature" component={UnderstandingHumanNature} />
    <Route exact path="/notes/vagabonding" component={Vagabonding} />
    <Route exact path="/notes/venture-deals" component={VentureDeals} />
    <Route exact path="/notes/wabi-sabi" component={WabiSabi} />
    <Route exact path="/notes/waking-up" component={WakingUp} />
    <Route exact path="/notes/what-i-talk-about-when-i-talk-about-running" component={WhatITalkAboutWhenITalkAboutRunning} />
    <Route exact path="/notes/when-things-fall-apart" component={WhenThingsFallApart} />
    <Route exact path="/notes/where-the-crawdads-sing" component={WhereTheCrawdadsSing} />
    <Route exact path="/notes/wherever-you-go-there-you-are" component={WhereverYouGoThereYouAre} />
    <Route exact path="/notes/white" component={White} />
    <Route exact path="/notes/white-fragility" component={WhiteFragility} />
    <Route exact path="/notes/who" component={Who} />
    <Route exact path="/notes/who-moved-my-cheese" component={WhoMovedMyCheese} />
    <Route exact path="/notes/why-were-polarized" component={WhyWerePolarized} />
    <Route exact path="/notes/why-we-sleep" component={WhyWeSleep} />
    <Route exact path="/notes/willpower" component={Willpower} />
    <Route exact path="/notes/wooden" component={Wooden} />
    <Route exact path="/notes/write-it-down-make-it-happen" component={WriteItDownMakeItHappen} />
    <Route exact path="/notes/zen-mind-beginners-mind" component={ZenMindBeginnersMind} />
    <Route exact path="/notes/zen-to-done" component={ZenToDone} />
    <Route exact path="/notes/zero-to-one" component={ZeroToOne} />
    <Route exact path="/now" component={NowPage} />
    <Route exact path="/podcast" component={PodcastPage} />
    <Route exact path="/updates" component={UpdatesPage} />
    <Route exact path="/videos" component={VideosPage} />
    <Route exact path="/work" component={WorkPage} />
    {work.map(job => <Route key={job.path} exact path={job.path} component={JobPage} />)}
    <Route exact path="/write" component={WritingsPage} />
    <Route
      exact
      path="/write/7-reasons-why-you-should-learn-how-to-program"
      component={ReasonsWhyYouShouldLearnHowToProgram}
    />
    <Route exact path="/write/anti-laissez-faire" component={AntiLaissezFaire} />
    <Route exact path="/write/approach-with-love" component={ApproachWithLove} />
    <Route exact path="/write/a-step-in-the-right-direction" component={AStepInTheRightDirection} />
    <Route exact path="/write/be" component={Be} />
    <Route exact path="/write/beginners-mind" component={BeginnersMind} />
    <Route exact path="/write/being-high" component={BeingHigh} />
    <Route exact path="/write/birthday-feels" component={BirthdayFeels} />
    <Route exact path="/write/blip" component={Blip} />
    <Route exact path="/write/books-i-read-in-2019" component={BooksIReadIn2019} />
    <Route exact path="/write/born-again" component={BornAgain} />
    <Route exact path="/write/consistency" component={Consistency} />
    <Route exact path="/write/dont-ask-for-it-to-be-easy" component={DontAskForItToBeEasy} />
    <Route exact path="/write/do-something" component={DoSomething} />
    <Route exact path="/write/do-the-opposite" component={DoTheOpposite} />
    <Route exact path="/write/doubt" component={Doubt} />
    <Route exact path="/write/failure" component={Failure} />
    <Route exact path="/write/family-matters" component={FamilyMatters} />
    <Route exact path="/write/favorite" component={Favorite} />
    <Route exact path="/write/fear" component={Fear} />
    <Route exact path="/write/finding-happiness" component={FindingHappiness} />
    <Route exact path="/write/goals-for-2020" component={GoalsFor2020} />
    <Route exact path="/write/goals-for-26" component={GoalsFor26} />
    <Route exact path="/write/goals-vs-resolutions" component={GoalsVsResolutions} />
    <Route exact path="/write/have-something-to-do" component={HaveSomethingToDo} />
    <Route
      exact
      path="/write/have-you-ever-tried-to-be-a-rapper"
      component={HaveYouEverTriedToBeARapper}
    />
    <Route exact path="/write/heaven" component={Heaven} />
    <Route exact path="/write/heres-the-thing-tho" component={HeresTheThingTho} />
    <Route exact path="/write/higher-standard" component={HigherStandard} />
    <Route
      exact
      path="/write/how-i-remember-every-thing-with-twos"
      component={HowIRememberEveryThingWithTwos}
    />
    <Route
      exact
      path="/write/how-people-wake-up-at-430-am-to-work"
      component={HowPeopleWakeUpAt430AMToWork}
    />
    <Route exact path="/write/how-to-become-a-programmer" component={HowToBecomeAProgrammer} />
    <Route
      exact
      path="/write/how-to-not-take-things-personally"
      component={HowToNotTakeThingsPersonally}
    />
    <Route
      exact
      path="/write/how-to-raise-the-communitys-level-of-intelligence"
      component={HowToRaiseTheCommunitysLevelOfIntelligence}
    />
    <Route exact path="/write/i-am-committed-to" component={IAmCommittedTo} />
    <Route exact path="/write/interesting-people" component={InterestingPeople} />
    <Route exact path="/write/i-think-i-can" component={IThinkICan} />
    <Route exact path="/write/laying-on-a-couch" component={LayingOnACouch} />
    <Route exact path="/write/levels" component={Levels} />
    <Route exact path="/write/liberty" component={Liberty} />
    <Route exact path="/write/listen-to-yourself" component={ListenToYourself} />
    <Route exact path="/write/love-vs-fear" component={LoveVsFear} />
    <Route exact path="/write/mvs" component={MVS} />
    <Route
      exact
      path="/write/new-years-resolutions-for-2020"
      component={NewYearsResolutionsFor2020}
    />
    <Route exact path="/write/nobody-gives-a-fuck" component={NobodyGivesAFuck} />
    <Route exact path="/write/one-day-at-a-time" component={OneDayAtATime} />
    <Route exact path="/write/on-the-shoulders-of-giants" component={OnTheShouldersOfGiants} />
    <Route exact path="/write/pain-and-pleasure" component={PainAndPleasure} />
    <Route exact path="/write/pause" component={Pause} />
    <Route exact path="/write/people-change" component={PeopleChange} />
    <Route exact path="/write/posibility" component={Posibility} />
    <Route exact path="/write/positive-thought-exercises" component={PositiveThoughtExercises} />
    <Route exact path="/write/potential" component={Potential} />
    <Route exact path="/write/read-this-if-you-are-stuck" component={ReadThisIfYouAreStuck} />
    <Route
      exact
      path="/write/realizations-from-real-negotiations"
      component={RealizationsFromRealNegotiations}
    />
    <Route exact path="/write/10-reasons-to-use-aware" component={ReasonsToUseAware} />
    <Route exact path="/write/10-reasons-to-use-muse" component={ReasonsToUseMuse} />
    <Route exact path="/write/resolution" component={Resolution} />
    <Route exact path="/write/sacrifice" component={Sacrifice} />
    <Route exact path="/write/see-what-you-want-to-see" component={SeeWhatYouWantToSee} />
    <Route exact path="/write/self-love" component={SelfLove} />
    <Route exact path="/write/solitude" component={Solitude} />
    <Route
      exact
      path="/write/the-best-california-burrito-in-san-diego"
      component={TheBestCaliforniaBurritoInSanDiego}
    />
    <Route exact path="/write/the-long-game" component={TheLongGame} />
    <Route exact path="/write/the-optimal-state" component={TheOptimalState} />
    <Route exact path="/write/the-point" component={ThePoint} />
    <Route exact path="/write/time" component={Time} />
    <Route exact path="/write/time-heals-all" component={TimeHealsAll} />
    <Route exact path="/write/top-10-benefits-of-writing-things-down" component={Top10BenefitsOfWritingThingsDown} />
    <Route exact path="/write/water-your-garden" component={WaterYourGarden} />
    <Route exact path="/write/6-ways-to-stop-forgetting-things-with-twos" component={WaysToStopForgettingThingsWithTwos} />
    <Route
      exact
      path="/write/we-ended-up-next-to-each-other"
      component={WeEndedUpNextToEachOther}
    />
    <Route exact path="/write/what-do-we-need" component={WhatDoWeNeed} />
    <Route exact path="/write/what-do-you-see" component={WhatDoYouSee} />
    <Route
      exact
      path="/write/what-happens-when-we-slow-down"
      component={WhatHappensWhenWeSlowDown}
    />
    <Route exact path="/write/what-is-meaningful" component={WhatIsMeaningful} />
    <Route exact path="/write/what-makes-you-feel-great" component={WhatMakesYouFeelGreat} />
    <Route exact path="/write/what-will-be" component={WhatWillBe} />
    <Route exact path="/write/what-you-love" component={WhatYouLove} />
    <Route exact path="/write/what-you-want" component={WhatYouWant} />
    <Route exact path="/write/when-youre-feeling-down" component={WhenYoureFeelingDown} />
    <Route exact path="/write/why-do-we-hurt-ourselves" component={WhyDoWeHurtOurselves} />
    <Route
      exact
      path="/write/why-everything-is-the-greatest"
      component={WhyEverythingIsTheGreatest}
    />
    <Route exact path="/write/why-i-built-aware" component={WhyIBuiltAware} />
    <Route exact path="/write/why-i-left-my-dream-job-at-google-for-twos" component={WhyILeftMyDreamJobAtGoogleForTwos} />
    <Route exact path="/write/why-me" component={WhyMe} />
    <Route exact path="/write/you-are-you" component={YouAreYou} />
    <Route exact path="/write/you-are-you-because-of-you" component={YouAreYouBecauseOfYou} />
    <Route exact path="/write/you-can-do-it" component={YouCanDoIt} />
    <Route exact path="/write/you-can-learn-anything" component={YouCanLearnAnything} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);
